<template>
  <section class="portal">
    <div class="grid grid-pad">
      <div class="col-1-1">
        <div class="ticket-status" v-for="ticket in this.$store.state.requestStatus" :key="ticket.id">
          <h3 style="font-weight: 400;">{{ $t("Ticket") }} <strong>#{{ticket.id}}</strong> {{ $t("has been marked as") }} <strong>{{ $t(ticket.status) }}</strong>.</h3>
        </div>
        <router-link v-if="this.$store.state.portalsettings.retailer" :to="{name: 'Retailer-Portal'}" class="btn btn-primary">{{ $t("Back to homescreen") }}</router-link>
        <router-link v-else :to="{name: 'Portal'}" class="btn btn-primary">{{ $t("Back to homescreen") }}</router-link>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Portal-RequestStatusResult',
  components: {},
  data () {
    return {}
  },
  beforeCreate () {
    if (window.location.href.indexOf('/retailer/') > -1) {
      this.$store.commit('setSettingRetailer', true)
    } else if (window.location.href.indexOf('/widget/') > -1) {
      this.$store.commit('setSettingWidget', true)
    }
  },
  mounted () {
    // set color
    if (window.location.href.indexOf('/retailer/') > -1) {
      if (this.$route.query.color) {
        document.documentElement.style.setProperty('--portal-color', decodeURI(this.$route.query.color))
        sessionStorage.setItem('retailer_color', decodeURI(this.$route.query.color))
      } else if (sessionStorage.getItem('retailer_color')) {
        document.documentElement.style.setProperty('--portal-color', sessionStorage.getItem('retailer_color'))
      }
    } else if (window.location.href.indexOf('/widget/') > -1) {
      if (this.$route.query.color) {
        document.documentElement.style.setProperty('--portal-color', decodeURI(this.$route.query.color))
        sessionStorage.setItem('widget_color', decodeURI(this.$route.query.color))
      } else if (sessionStorage.getItem('widget_color')) {
        document.documentElement.style.setProperty('--portal-color', sessionStorage.getItem('widget_color'))
      }
    }
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>
